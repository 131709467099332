.section-titlee h2 {
  font-size: 36px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}
.section-titlee p {
  font-size: 18px;
  color: #777;
  margin-bottom: 50px;
}

.card-title {
  margin-top: 0;
  color: #0d6efd;
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.contact-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 60px;
}

.contact-card {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 40px;
  width: 30%;
  transition: all 0.3s ease-in-out;
  margin-bottom: 80px;
}
@media (max-width: 992px) {
  .contact-card {
    width: 45%;
  }
}

@media (max-width: 576px) {
  .contact-card {
    width: 100%;
  }
}

.contact-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.2);
}

.contact-card p {
  font-size: 1.1rem;
  margin-bottom: 15px;
  line-height: 1.4;
}


.contact-card .card-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.contact-card a {
  color: #0d6efd;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.contact-card a:hover {
  color: #0047b3;
  text-decoration: underline;
}

.contact-card .fa {
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }
  
  .contact-card {
    width: 100%;
    margin-bottom: 30px;
  }
}
.section-title h2 {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}

.section-title h3 {
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.5;
}
.contact-section {
  margin-bottom: 100px;
}