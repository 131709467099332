.slider {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 500px; /* ajusta la altura a tus necesidades */
}

.slider__item {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

.slider__item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(70%) opacity(0.8);
}

.slider__caption {
  position: absolute;
  color: #fff;
  transition: all 0.3s ease;
  opacity: 1;
  padding: 0 10%;
  text-align: left;
  left: 0;
  right: 0;
  bottom: 15%;
  z-index: 3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slider__caption h2 {
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  margin-bottom: 2rem;
}
.slider__caption p {
  font-size: 1.8rem;
  color: #fff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  margin-bottom: 2.5rem;
}

.slider__caption .slider__button {
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 2px solid #1292EE;
  display: inline-block;
  padding: 10px 20px;
  background-color: #1292EE;
  color: #fff;
  border-radius: 0px;
  transition: all 0.3s ease;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  position: relative;
  margin-top: 30px;
}

.slider__caption .slider__button:hover {
  border: 2px solid #000;
  background-color: #000;
}

.slider__container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.slider__item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0));
  z-index: 2;
}
.slider__caption .slider__button {
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 2px solid #1292EE;
  display: inline-block;
  padding: 10px 20px;
  background-color: #1292EE;
  color: #fff;
  border-radius: 0px;
  transition: all 0.3s ease;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  position: relative;
  margin-top: 30px;
  align-self: flex-start;
}

.slider__caption .slider__button:hover {
  border: 2px solid #000;
  background-color: #000;
}