.footer-container {
  background-color: #252525;
  color: #ffffff;
  padding: 40px 0;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-left img {
  height: 50px;
  width: auto;
}

.footer-right {
  text-align: right;
}

.footer-right p {
  font-size: 14px;
  line-height: 24px;
  margin: 5px 0;
}

.footer-line {
  width: 80%;
  height: 1px;
  background-color: #ffffff;
  margin: 20px auto;
}
.footer-bottom {
  width: 100%;
  text-align: center;
}

.footer-bottom p {
  font-size: 12px;
  line-height: 18px;
  color: #b7b7b7;
}