.services-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 100px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px 0px;
}
.service-titulo_sub h2 {
  font-size: 36px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}
.service-titulo_sub p {
  font-size: 18px;
  color: #777;
  margin-bottom: 50px;
}
  
  .service-card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    width: 30%;
    margin: 10px 20px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-in-out;
    aspect-ratio: 1/1;
    background-color: #fff;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  }
  
  .card-img-container {
    position: relative;
    overflow: hidden;
    aspect-ratio: 1/1;
  }
  
  .card-img-container img {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .card-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-img-overlay:hover {
    opacity: 1;
  }
  
  .card-img-overlay a {
    color: #fff;
    font-size: 24px;
  }
  
  .service-card .card-header {
    background-color: #FCC07E;
    border-radius: 10px 10px 0 0;
    padding: 20px;
    color: #fff;
  }
  
  .service-card .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    height: 100%;
  }
  
  .service-card .card-body h5 {
    font-weight: bold;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .service-card .card-body p {
    color: #777;
    margin-bottom: 20px;
  }
  
  .service-card .card-body a {
    display: inline-block;
    background-color: #FCC07E;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 15px 40px;
    border-radius: 50px;
    position: relative;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    margin-top: 20px;
  }
  
  .service-card .card-body a:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  }
  
  .service-card .card-body a:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    background-color: #FCC07E;
    border-radius: 50%;
  }
  @media screen and (max-width: 992px) {
    .service-card {
      width: 45%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .service-card {
      width: 100%;
    }
  }


  .services-container .service-card .btn-primary {
    background-color: transparent;
    border: none;
    color: #0077FF;
    font-size: 16px;
  }

  .service-card {
    margin: 20px;
  }
  
  .icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }