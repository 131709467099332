.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
}
.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
}


.menu--open .menu__list {
  transform: translateX(0);
}

.menu__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.menu__logo {
  display: flex;
  align-items: center;
}

.menu__logo img {
  height: 40px;
  width: auto;
  margin-left: 10px;
}

.menu__list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  border-bottom: none;
}

.menu__item {
  margin: 0 15px;
}

/* General */
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  height: var(--menu-item-height);
}

li a {
  display: block;
  height: 100%;
  color: var(--text-color);
  text-decoration: none;
  padding: 0 20px;
  line-height: var(--menu-item-height);
}

.menu-icon__line {
  display: block;
  width: 30px;
  height: 3px;
  background-color: var(--text-color);
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
}

.menu--open {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100% - 80px);
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo semitransparente */
}

.menu__item a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  transition: color 0.3s ease;
}

.menu__item a:hover {
  color: #1292EE;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 999;
}

.menu-icon__line {
  width: 30px;
  height: 2px;
  background-color: #fff;
  margin-bottom: 6px;
}

@media (min-width: 768px) {
  .menu__header {
    justify-content: flex-end;
  }

  .menu__list {
    transform: translateX(0);
    position: static;
    height: auto;
    width: auto;
    background-color: transparent;
    margin: 0;
  }

  .menu__item {
    margin: 0 15px;
  }

  .menu-icon {
    display: none;
  }
}
@media (max-width: 767px) {
  .menu--open .menu__list {
    transform: translateX(0);
  }

  .menu__item {
    margin: 0;
  }

  .menu__item a {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .menu__item a {
    font-size: 15px;
  }
}
